import React from "react";
import '../styles/MainBanner.css';


function MainBanner({title, text, link, link_text, imgSrc, alt}) {
    return <div>
        <div className="main-banner row">
            <div className="col-12 col-sm-4 h-100 main-banner-img-container">
                <img className={"main-banner-img my-3 mx-auto"} src={imgSrc} alt={alt}/>
            </div>
            <div className="col-12 col-sm-8 my-auto h-100 main-banner-text-block">
                <div className="row align-middle h-100 main-banner-text-holder">
                    <div className="main-banner-text my-auto">
                        <h2>
                            {title}
                        </h2>
                        <p>
                            {text}
                        </p>
                        <a className={"btn btn-success glow-on-hover"} href={link}><i
                            className={'bi bi-gift'}></i> {link_text} </a>
                        <div className={'mt-3'}>
                            <a className={"btn btn-outline-light"}
                               href={"https://wa.me/77087740721?text=Здравствуйте! Хотел(а) бы узнать больше о каспи боте pricefeed.kz и его возможностях."}
                               target={"_blank"}>
                                <i className={"bi bi-whatsapp me-1"}></i>
                                Получить консультацию
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default MainBanner;